@import "./variables";

// MEDIA QUERIES
@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet) {
        @content;
    }
}

@mixin small-desktop {
    @media screen and (max-width: $small-desktop) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: $desktop) {
        @content;
    }
}

@mixin large-desktop {
    @media screen and (min-width: $large-desktop) {
        @content;
    }
}

@mixin container {
    @include large-desktop {
        width: 1320px;
    }

    @include desktop {
        width: 960px;
    }

    @include small-desktop {
        width: 720px;
    }

    @include tablet {
        width: 540px;
    }

    @include mobile {
        width: 100%;
    }

    width: 1140px;
    margin: auto;
}

@mixin title2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 150%;

    margin: 0;
}

@mixin title4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 150%;

    margin: 0;
}

@mixin title5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;

    margin: 0;
}

@mixin title6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    margin: 0;
}

@mixin button-secondary {
    border: none;

    background-color: $color-primary-1;

    color: $color-primary;
}

@mixin button-third {
    background-color: transparent;
}

@mixin button-width-fixed {
    width: 155px;
}