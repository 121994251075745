// COLORS
$color-primary: #625DF5;
$color-primary-1: #E1E1F7;

$color-red: #FF4D4F;

$color-green: #00BC8F;

$color-dark-blue: #16194F;

$color-violet-gray: #6C6685;

$color-dark: #212121;
$color-dark-gray: #D1D1D1;
$color-gray: #F1F0F3;
$color-white: #FFF;


// BREAK POINTS
$mobile: 575px;
$tablet: 767px;
$small-desktop: 991px;
$desktop: 1199px;
$large-desktop: 1400px;